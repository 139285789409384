a {
  text-decoration: none;
}
* {
  margin: 0;
  padding: 0;
}
.error {
  color: red;
  font-size: 0.6em;
  margin-bottom: 10px;
}

:root {
  /* primary colors */
  --white: #ffffff;
  --black-titles: #191919;
  --skyblue-color: #62ceee;
  --red-color: #ed1e37;
  --red-color-100: #982432;
  --box-shadow-red-color: #ED1E3780;
  --transparent-red-color: #fbcad0;
  --blue-color: #3e3da9;
  --blue-color-hover: #383798;
  --blue-color-100: #5b5ab6;
  --blue-color-200: #26265a;
  --blue-dark-color: #1F1F55;
  --transparent-blue-color: #d8d8ee;
  --box-shadow-blue-color: #6C6BD380;
  --green-color: #7dd65e;
  --green-color-100: #427f2c;
  --transparent-green-color: #d8f3ce;
  --box-shadow-green-color: #7DD65E80;
  --profile-header: #C0EBF880;
  --input-border: #909090;
  --shadow-skyblue: #12738F33;
  --shadow-blue: #1F1F5533;

  --yellow-color: #ffc43b;
  --title-register-color: #1f1f55;

  /* center boxes colors */
  --blue-box-headercolor: #1f1f55;
  --green-box-headercolor: #427f2c;
  --red-box-headercolor: #8a0515;
  --skyblue-box-headercolor: #12738f;
  --yellow-box-headercolor: #b98201;

  --box-mild-report-box: #5CCB5F; 
  --box-mild-report-box-dark: #4DA243; 
  
  --box-middle-report-box: #FF9800;
  --box-middle-report-box-dark: #FF8117;

  --box-serious-report-box: #CD0C36;
  --box-serious-report-box-dark: #A80824;

  --box-comment-report-box: #3D6F89;
  --box-comment-report-box-dark: #212F4A;

  --no-notification-background: #e3e3f2;
  --no-notification-color: #1f1f55;
  --background-header-input: #f7f7f7;
  --welcome-box-background: #dff5fb;
  --dashboard-color: #6c6bd3;
  --yellow-color-title: #b98201;
  --blue-input-focus-color: #a2e2f5;
  --disabled-input-color: #a2a2a2;
  --subtitle-color: #777;
  --input-text-color: #cccccc;
  --grey-items-separaton: #7070701A;

  --home-title: #1f1f55;
  --home-subtitle: #6e6dbe;

  --box-shadow: #00000029;
  --footer-color: #f0f0f0;

  --background-patient: #fafafa;
  --text-area-grey: #E3E3E3;
  --text-grey: #4E4E4E;
  --text-toggle-gray: #9A9A9A;
  --hover-box: #f9f9f9;
  --box-img-shadow: #0000001A;
  --blue-transparent-100: #6C6BD31F;
  --blue-transparent-200: #3E3DA90D;
  --blue-transparent-300: #3E3DA980;
  --not-found-blue: #1F2154;
  --error-red: #EF4D62;
  --skyblue-dark: #1B738E;
  --modal-white: #ffffffcc;
  --blue-button-opacity: #C2C2E3;
  --red-option: #F14B5F;
  --table-color: #F8F7FD;



  /* PRIMARY COLORS */

  /* --primary-color: #3E3DA9;
  --primary-variant-color: #1F1F55;
  --on-primary-color: #FFFFFF;
  --background: #FFFFFF;
  --on-background: #1F1F55;
  --on-background-variant: #191919;
  --on-background-soft: #4E4E4E;
  --warning: #f1eb4b; */


  /* CARDS */

  /* --primary-card-comment: #12738F;
  --primary-card-serious-report: #CD0C36;
  --primary-card-mild-report: #5CCB5F;
  --primary-card-avarage-report: #FF9800;
  --secondary-card-comment: #055052;
  --secondary-card-serious-report: #8A0515;
  --secondary-card-mild-report: #427F2C;
  --secondary-card-avarage-report: #FF6D2A;
  --on-card: #FFFFFF; */


  /* SECTIONS */

  /* --primary-section-vital-signs: #ED1E37;
  --primary-section-medicines: #7dd65E;
  --primary-section-calendar: #ffC43B;
  --primary-section-profile: #62CEEE;
  --primary-section-sypmtoms: #3E3DA9;
  --secondary-section-vital-signs: #8A0515;
  --secondary-section-medicines: #427F2C;
  --secondary-section-calendar: #B98201;
  --secondary-section-profile: #12738F;
  --secondary-section-sypmtoms: #1F1F55;
  --on-section: #FFFFFF; */


  /* PROFILE */

  /* --main-profile-color: #62CEEE; 
  --background: #FFFFFF;
  --on-background-profile: #12738F; 
  --on-background-variant: #191919;  */


  /* MISCELLANEOUS */

  /* --disable-button: #E3E3E3; 
  --input-text: #191919; 
  --input-background: #FFFFFF; 
  --input-placeholder: #E3E3E3; 
  --input-border: #909090; 
  --grey-background: #707070; 
  --on-grey-background: #FFFFFF;  */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.1em;
  font-weight: bold;
}

p {
  font-size: 0.8rem;
  line-height: 0.9rem;
  font-weight: 400 !important;
  font-family: "Nunito", sans-serif;
}

label {
  font-weight: normal;
}

i {
  /* vertical-align: middle; */
  display: flex
}

.title-register {
  color: var(--title-register-color);
}

/* ::-webkit-scrollbar {
  display: none;
} */

::-webkit-scrollbar {
  width: 25px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 10px 10px transparent;
  /* margin: 1px 0px;  */
}
::-webkit-scrollbar-thumb {
  min-height: 50px;
  border: 10px solid rgba(0, 0, 0, 0);
  box-shadow: inset 0 0 10px 10px #3E3DA366;
  background-clip: padding-box;
  border-radius: 9999px;
  background-color: transparent;
}

.disable-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

button {
  cursor: pointer;
  font-family: Nunito Sans;
  border: none;
  outline: none;
}

/* ::-webkit-scrollbar-thumb:hover {
  background: #555; 
} */
